<template>
    <v-card color="background" height="100%" style="border-radius: 0px" >
        <v-app-bar app dark color="primary" clipped-left v-if="!isMobile()">
            <div class="menu-button-container">
                <!-- " -->
                <v-app-bar-nav-icon class="menu-button" id="menuItem" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </div>

            <router-link class="title" to="/home">
                <v-img id="logo" class="logo" :src="logoUrl" max-height="40" max-width="180" contain></v-img>
            </router-link>

            <div class="ml-16 d-none d-sm-flex">
                <img src="@/assets/logo-baitatec.svg" width="160px" />
            </div>


            <v-spacer></v-spacer>

            <v-spacer></v-spacer>

            <v-btn icon disabled >
                <v-badge color="green" overlap dot>
                    <v-icon>mdi-bell-outline</v-icon>
                </v-badge>
            </v-btn>

            <v-btn icon disabled>
                <v-icon>mdi-theme-light-dark</v-icon>
            </v-btn>

            <v-menu ref="menu" :close-on-content-click="false" offset-x offset-y max-height="800" min-width="300" right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="mr-2" v-bind="attrs" v-on="on" id="menuSetting" :disabled="isDisabled(user)" >
                        <v-icon>mdi-cog-outline</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <template v-for="(menuOption, index) in toolbarMenu">
                        <v-list-group v-if="menuOption && menuOption.children" :key="index" no-action>
                            <template v-slot:activator>
                                <v-list-item-icon>
                                    <v-icon>{{ menuOption.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        menuOption.name
                                    }}</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <MenuItem v-for="child in menuOption.children" :key="child.id" :item="child" :user="user">
                            </MenuItem>
                        </v-list-group>
                        <MenuItem :key="index" :item="menuOption" v-if="!menuOption || !menuOption.children" :user="user">
                        </MenuItem>
                    </template>
                </v-list>
            </v-menu>

            <v-btn icon class="mr-12" disabled>
                <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>

            <!-- <v-avatar class="mr-4">
                <img src="https://randomuser.me/api/portraits/men/50.jpg" />
            </v-avatar>
            
            <div class="logged-user d-none d-sm-flex">
                <span class="logged-user-name font-weight-bold">{{ user.name }}</span>
                <span class="logged-user-email">{{ user.email }}</span>
            </div> -->

            <v-menu min-width="200" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <div class="logged-user d-none d-sm-flex ml-4" v-bind="attrs" v-on="on">
                        <span class="logged-user-name font-weight-bold">{{ user.name }}</span>
                        <span class="logged-user-email">{{ user.email }}</span>
                    </div>

                    <v-avatar v-bind="attrs" v-on="on">
                        <img :src="'https://ui-avatars.com/api/?name=' + user.name" />
                    </v-avatar>
                </template>
                <v-list>
                    <template v-for="(menuOption, index) in profileMenu">
                        <MenuItem :key="index" :item="menuOption" v-if="!menuOption || !menuOption.children"  :user="user">
                        </MenuItem>
                    </template>
                </v-list>
            </v-menu>
        </v-app-bar>
        <!-- <ProgressBar></ProgressBar> -->

        <v-navigation-drawer app v-model="drawer" temporary hide-overlay touchless>
            <v-list dense>
                <span class="menu-title">Menu</span>
            </v-list>
            <v-list dense subheader v-for="(menuGroup, index) in sidebarMenu" :key="index">
                <template v-if="menuGroup.children.length">
                    <v-subheader class="menu-subheader">{{ menuGroup.name }}</v-subheader>
                        <MenuItem :item="menuOption" v-for="(menuOption, index) in menuGroup.children" :key="index" :user="user">
                    </MenuItem>
                </template>
            </v-list>
        </v-navigation-drawer>

        <router-view></router-view>
    </v-card>
</template>
  
<script>
// import userApi from "@/common/api/user.api";
import { eventHub } from "@/main";

import router from '../router';

import MenuItem from "@/layouts/components/MenuItem";
import toolbarMenu from "@/common/data/toolbar-menu.data"
import profileMenu from "@/common/data/profile-menu.data"
import sidebarMenu from "@/common/data/sidebar-menu.data"

export default {
    name: "PrivateLayout",

    components: {
        MenuItem
    },

    data() {
        return {
            logoUrl: null,
            menuGroups: [],
            drawer: false,
            searchTerm: null,
            user: null,
            displaySettingsMenu: false,
            toolbarMenu: toolbarMenu,
            sidebarMenu: sidebarMenu,
            profileMenu: profileMenu
        };
    },

    computed: {
        routeName() {
            return this.$route.name;
        },
    },

    created() {
        this.user = this.$root.user;

        // eventHub.$on("close-menu", () => {
        //     this.closeMenu();
        // });

        eventHub.$on("logout", () => {
            this.logout();
        })

        this.setActionButtons();

    },

    destroyed() {
        eventHub.$off("THEME_UPDATED");
    },

    methods: {
        setActionButtons() {
            // Grupo
        },

        // closeMenu() {
        //     this.$refs.menu.save()
        // },

        menuActionClick(event) {
            event.preventDefault()
        },

        isDisabled(dataUser) {
            return dataUser.permissions.some(permission => permission.path === '/tracking/2');
        },
        
        isMobile() {
            return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        },
        async logout() {
            // if (await this.$root.$confirm(true, "user.logout")) {
            //     userApi.clearcurrentUser;
            //     this.$router.push({ name: "Login" });
            // }

            localStorage.clear();
            this.$router.push("/login");
            setTimeout(()=>{
                window.location.reload();
            }, 50);

        },
    },
};
</script>
  
<style scoped lang="scss" >
.title {
    color: var(--v-primary-base);
    text-decoration: none;
}

.menu-button-container {
    background-color: var(--v-secondary-base) !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 80px;
    text-align: center;
}

.menu-button {
    margin: auto;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.logged-user {
    text-align: left;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 400;
    flex-direction: column;

    &-name {
        display: block;
    }

    &-email {
        display: block;
    }
}

.menu-subheader {
    padding-left: 16px;
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.001px;
}

.menu-title {
    padding-left: 16px;
    color: var(--v-important-base);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}
</style>