const data = [
  {
    name: "Home",
    children: [
      { name: "Dashboard", icon: "mdi-view-dashboard-outline", route: "/home" },
    ],
  },
  {
    name: "Monitoramento",
    children: [
      { name: "Rastreio", icon: "mdi-broadcast", route: "/tracking/1" },
      { name: "Histórico", icon: "mdi-map-marker-path", route: "/tracking/2" },
      { name: "Antenista", icon: "mdi-map-marker-distance", route: "/tracking/3" }
    ],
  },
  {
    name: "Cadastros",
    children: [
      { name: "Veículos", icon: "mdi-truck-fast-outline", route: "/vehicles" },
      // { name: "Técnicos", icon: "mdi-car-wrench", route: "/404" },
      // { name: "Redes", icon: "mdi-wifi", route: "/404" },
    ],
  },
  {
    name: "Equipamentos",
    children: [
      { name: "Gestão", icon: "mdi-file-chart-outline", route: "/equipments" },
      { name: "Modelos", icon: "mdi-memory", route: "/models" },
      // { name: "Firmwares", icon: "mdi-database-outline", route: "/404" },
    ],
  },
];

export default [...data];
