<template>
    <v-list-item 
        link 
        :to="item.route" 
        color="primary" 
        @click.native="emitEvent(item)"
        v-if="isAllowed(item.route, 'get')"
    >
        <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import { eventHub } from "@/main";
import isAllowed from "@/common/util/isAllowed"; 


export default {
    name: "MenuItem",

    props: {
        item: Object,
        user: Object,
    },
    methods: {
        emitEvent(item) {
            if (item.event) {
                eventHub.$emit(item.event)
                return;
            }

            eventHub.$emit('close-menu');
        },

        isDisabled(dataUser, menuOption) {
            const hasTrackingGatewayPermission = dataUser.permissions.some(permission => permission.path === '/tracking/gateway');
            const allowedMenus = ["Histórico", "Antenista", "Sair"];

            if (hasTrackingGatewayPermission) {
                return !allowedMenus.includes(menuOption.name);
            }
            
            return false;
        },
        isAllowed,
    },
};
</script>
  
<style lang="scss" scoped></style>