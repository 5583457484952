import user from "../util/identify";

// Dados originais do menu
let data = [
  {
    name: "Usuários",
    icon: "mdi-account-multiple-outline",
    children: [
      {
        name: "Gestão de Usuários",
        icon: "mdi-account-circle-outline",
        route: "/users",
      },
    ],
  },
];

const { client } = user.identify();

if (client) {
  data.push({
    name: "Detalhes da Conta",
    icon: "mdi-store-outline",
    route: `/clients/${client}`,
  });
} else {
  data.push({
    name: "Clientes",
    icon: "mdi-store-outline",
    route: "/clients",
  });
}

export default data;